import { api } from "./index";

export const getAllPersonalizations = () => {
  return new Promise((resolve, reject) => {
    api
      .get(`personalizations`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response: { data } }) => reject(data));
  });
};

export const getPersonalizations = (id) => {
  return new Promise((resolve, reject) => {
    api
      .get(`personalizations/${id}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response: { data } }) => reject(data));
  });
};

export const updatePersonalizations = (id, formData) => {
  formData.append("_method", "PUT");
  return new Promise((resolve, reject) => {
    api
      .post(`personalizations/${id}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response: { data } }) => reject(data));
  });
};
